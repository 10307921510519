import { defineStore } from "pinia";
import useApi from "@/hooks/bff";

const bff = useApi();

export const useAppUserStore = defineStore({
  id: "appUser",
  state: () => {
    const state: { [k: string]: any } = {
      user: null,
      identityPermissions: {},
    };

    return state;
  },
  actions: {
    async fetchUserIdentityPermissions() {
      const userId = this.user?.attributes?.sub;

      if (!userId) {
        return;
      }

      const {
        data: { data: response = {} },
      } = await bff.post("/v1/getIdentityPermissions").catch(() => ({ data: {} }));

      this.identityPermissions = response?.identityPermissions ?? {};
    },
  },
  getters: {
    name(): string {
      return this.user?.attributes?.name || "Menu";
    },
  },
});
